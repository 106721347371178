<template>
  <div class="px-4">
    <h3 class="mx-auto mb-6">{{ $t('WhatWouldLikeListenSection.title') }}</h3>
    <div class="mx-auto max-w-xl">
      <Searchbar />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { searchPanelVisible } = useNavbarSearchPanel()
const localePath = useLocalePath()
</script>
